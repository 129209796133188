import React from 'react';
import { useTranslation } from 'react-i18next';

import KdButton from '../../../shared/KdButton';
import { useAppDispatch } from '../../../useAppDispatch';

type EnableButtonProps = {
  featureEnabled: boolean;
  featureName: string;
  buttonStatus: string;
};

const FeatureFlagButton = ({
  featureEnabled,
  featureName,
  buttonStatus,
}: EnableButtonProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isEnableButton = buttonStatus === 'enable';

  const handleClick = () => {
    // eslint-disable-next-line no-console
    console.info(
      `!!! Feature ${isEnableButton ? 'Enabled' : 'Disabled'} - ${featureName}`
    );

    isEnableButton
      ? dispatch({
          type: 'SET_FEATURE_FLAG_STATUS',
          payload: {
            featureFlagLabel: featureName,
            featureFlagDisplayStatus: true,
          },
        })
      : dispatch({
          type: 'SET_FEATURE_FLAG_STATUS',
          payload: {
            featureFlagLabel: featureName,
            featureFlagDisplayStatus: false,
          },
        });
  };

  return (
    <KdButton
      disabled={isEnableButton ? featureEnabled : !featureEnabled}
      type="button"
      className={`button ${isEnableButton ? '--enable' : '--disable'}`}
      styles={{
        width: '100px',
        minWidth: '100px',
      }}
      onClick={handleClick}
    >
      {isEnableButton ? t('Enable') : t('Disable')}
    </KdButton>
  );
};

export default FeatureFlagButton;
