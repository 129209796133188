import React from 'react';

import { correctPayThruUrl } from '../../../functions/paythru';
import useCallApi from '../../../hooks/useCallApi';
import KdButton from '../../../shared/KdButton';
import { PaymentDetails } from '../../../types/paymentDetails';

type Props = { paymentDetails: PaymentDetails };

type Response = {
  redemption_url: string;
  token: string;
};

const PayThruSandBox = ({ paymentDetails }: Props) => {
  const [transactionNumber, setTransactionNumber] = React.useState('');
  const [paymentStatus, setPaymentStatus] = React.useState('');

  const { loading, response, error, fetchData } = useCallApi<Response>({
    apiCall: undefined,
    apiArgs: {
      paymentDetails,
    },
    dependencies: [],
    autoCall: false,
  });

  React.useEffect(() => {
    const url = window.location.href;
    if (
      !url.includes('successful-payment') &&
      !url.includes('failed-payment')
    ) {
      return;
    }
    const correctedUrl = correctPayThruUrl(url);
    const successful = correctedUrl.includes('#/developers/successful-payment');
    const failed = correctedUrl.includes('#/developers/failed-payment');
    const transactionKey = correctedUrl.split('transactionKey=')[1];

    if (successful) {
      setPaymentStatus('successful');
      setTransactionNumber(transactionKey);
    } else if (failed) {
      setPaymentStatus('failed');
      setTransactionNumber('');
    }
  }, []);

  if (response) {
    window.location.href = `${response.redemption_url}?tokenId=${response.token}`;
    return null;
  }

  return (
    <>
      {!paymentStatus && (
        <KdButton
          type="button"
          apiCalling={loading}
          onClick={fetchData}
        >
          {loading ? 'Processing...' : 'Make a payment'}
        </KdButton>
      )}

      <div className="text-xl text-white text-center pt-20">
        {paymentStatus && (
          <>
            <h2 className="text-3xl">
              {paymentStatus === 'successful'
                ? 'Your payment was successful'
                : 'Your payment has failed'}
            </h2>

            {transactionNumber !== '' && (
              <h3>Your transaction number: {transactionNumber}</h3>
            )}
            <KdButton
              type="button"
              className="mt-10"
              apiCalling={loading}
              onClick={fetchData}
            >
              {loading ? 'Processing...' : 'Make another payment'}
            </KdButton>
            {error && (
              <pre className="text-red text-2xl">
                {JSON.stringify(error, null, 2)}
              </pre>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default PayThruSandBox;
