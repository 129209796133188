import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  text: string;
  url: string;
}

const HelperLink: React.FC<Props> = ({ className, text, url }) => {
  const classes = cn(
    'inline-block text-contentColor text-[12px] hover:text-accentPrimary transition-[color] duration-200 mb-[20px]',
    className
  );

  return (
    <Link
      className={classes}
      to={url}
    >
      {text}
    </Link>
  );
};
export default HelperLink;
