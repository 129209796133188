import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

import KdBreakpoints from '../../shared/Breakpoints/breakpoint';
import PrivatePage from '../../shared/PrivatePage/PrivatePage';
import { PaymentDetails } from '../../types/paymentDetails';
import FeatureFlagsList from './components/FeatureFlagsList';
import GenericComponents from './components/GenericComponents';
import GenericComponents2 from './components/GenericComponents2';
import PayThruSandBox from './components/PayThruSandBox';
import SentryTester from './components/SentryTester';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const DeveloperToolsView = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const paymentDetails: PaymentDetails = {
    item_name: 'Bay',
    item_price: 2.9,
    item_quantity: 1,
    item_reference: 'test',
    success_url: window.location.origin + '/#/developers/successful-payment',
    cancel_url: window.location.origin + '/#/developers/failed-payment',
    auto_redirect: 1,
  };

  return (
    <PrivatePage className="flex">
      <Box className="h-full w-full mt-10">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit"
            sx={{
              borderBottom: 0.5,
              borderColor: 'white',
              backgroundColor: '#010',
            }}
          >
            <Tab label="PayThru" />
            <Tab label="Sentry" />
            <Tab label="Feature Flags" />
            <Tab label="Generic components 1" />
            <Tab label="Generic components 2" />
            <Tab label="Breakpoints" />
          </Tabs>
        </Box>
        <TabPanel
          value={value}
          index={0}
        >
          <PayThruSandBox paymentDetails={paymentDetails} />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
        >
          <SentryTester />
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
        >
          <div className="overflow-auto h-full">
            <FeatureFlagsList />
          </div>
        </TabPanel>
        <TabPanel
          value={value}
          index={3}
        >
          <GenericComponents />
        </TabPanel>
        <TabPanel
          value={value}
          index={4}
        >
          <GenericComponents2 />
        </TabPanel>
        <TabPanel
          value={value}
          index={5}
        >
          <KdBreakpoints />
        </TabPanel>
      </Box>
    </PrivatePage>
  );
};

export default DeveloperToolsView;
