import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { ReactComponent as EditImage } from '../../../assets/images/pen.svg';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import KdDropdown from '../../../shared/Dropdown/KdDropdown';
import HelperLink from '../../../shared/Forms/HelperLink';
import KdCheckbox from '../../../shared/Forms/KdCheckbox';
import KdInput from '../../../shared/Forms/KdInput';
import KdSearch from '../../../shared/Forms/KdSearch';
import KdSorting from '../../../shared/Forms/ui/KdSorting';
import KdButton from '../../../shared/KdButton';
import KdTabContent from '../../../shared/Tabs/KdTabContent';
import tabsStyles from '../../../shared/Tabs/tabsStyles';

const options = [
  { key: 'option1', label: 'Option 1' },
  { key: 'option2', label: 'Option 2' },
];

const GenericComponents = () => {
  const [isSortingShow, setIsSortingShow] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);

  const { isMediumScreen } = useWindowDimensions();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onChange = (value: string) => {
    //custom logic
  };

  const handleIconClick = () => {
    setIsSortingShow(!isSortingShow);
  };

  const handleSorting = (key: string) => {};

  return (
    <>
      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Buttons:
        </Typography>
        <div className="flex items-center nextChild:ml-[20px] mb-[20px]">
          <KdButton>default (primary) button</KdButton>
          <KdButton size="small">small button</KdButton>
          <KdButton size="tiny">tiny button</KdButton>
          <KdButton size="extraSmall">xs</KdButton>
        </div>
        <div className="flex items-center nextChild:ml-[20px] mb-[20px]">
          <KdButton variant="secondary">secondary button</KdButton>
          <KdButton variant="tertiary">tertiary button</KdButton>
          <KdButton variant="success">success button</KdButton>
          <KdButton variant="warning">warning button</KdButton>
        </div>
        <div className="flex items-center nextChild:ml-[20px]">
          <KdButton apiCalling={true}>with loading</KdButton>
          <KdButton withIcon>
            with icon right
            <EditImage className="w-[12px] ml-[10px]" />
          </KdButton>
          <KdButton withIcon>
            <EditImage className="w-[12px] mr-[10px]" />
            with icon left
          </KdButton>
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Form Input:
        </Typography>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">Default:</div>
          <KdInput
            className="!w-[340px]"
            name="input name"
            placeholder="Standard input"
          />
        </div>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">With error:</div>
          <KdInput
            className="!w-[340px]"
            error="error message"
            name="input name"
            placeholder="Input with error"
          />
        </div>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">With icon:</div>
          <KdInput
            name="input name"
            placeholder="Input with icon"
            showIcon
            className="!w-[340px]"
          />
        </div>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">With label:</div>
          <KdInput
            className="!w-[340px]"
            name="input name"
            placeholder="Input with label"
            label="Label"
            showIcon
          />
        </div>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">Disabled:</div>
          <KdInput
            className="!w-[340px]"
            name="input name"
            placeholder="Input with label"
            showIcon
            disabled
          />
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Dropdown:
        </Typography>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">Default:</div>
          <KdDropdown
            className="w-[340px]"
            options={options}
            placeholder="Select..."
            onChange={onChange}
          />
        </div>
        <div className="flex items-center mb-[20px]">
          <div className="w-[160px]">With default value:</div>
          <KdDropdown
            className="w-[340px]"
            options={options}
            placeholder="Select..."
            defaultValue={options[0]?.key}
            onChange={onChange}
          />
        </div>
        <div className="flex items-center">
          <div className="w-[160px]">Disabled:</div>
          <KdDropdown
            className="w-[340px]"
            options={options}
            placeholder="Select..."
            onChange={onChange}
            disabled
          />
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Search:
        </Typography>
        <KdSearch
          className="w-[500px]"
          id="id"
          name="name"
          placeholder="Search by"
          onChange={() => null}
        />
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Search with sorting (desktop/tablet/mobile):
        </Typography>
        <div className="flex items-center">
          <KdSearch
            id="id"
            name="name"
            className="w-[500px]"
            placeholder="Search..."
            showRightIcon={isMediumScreen}
            onChange={() => null}
            onRightIconClick={handleIconClick}
            renderMenu={
              isMediumScreen &&
              isSortingShow && (
                <KdSorting
                  isSmallScreen
                  onFilterChange={handleSorting}
                  options={options}
                />
              )
            }
          />
          {!isMediumScreen && (
            <KdSorting
              onFilterChange={handleSorting}
              options={options}
            />
          )}
        </div>
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Checkbox:
        </Typography>
        <KdCheckbox onChange={() => null} />
        <KdCheckbox
          checked
          onChange={() => null}
        />
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Helper link text:
        </Typography>
        <HelperLink
          text="my helper text link"
          url="/"
        />
      </div>

      <div className="mb-[40px]">
        <Typography
          className="text-[20px] font-semibold mb-[20px] text-accentPrimary"
          component="h4"
          variant="h4"
        >
          Tabs:
        </Typography>
        <Tabs
          indicatorColor="secondary"
          onChange={handleChange}
          sx={tabsStyles}
          textColor="inherit"
          value={value}
        >
          <Tab label="Tab 1" />
          <Tab label="Tab 2" />
          <Tab
            label="Tab 3 (disabled)"
            disabled
          />
        </Tabs>
        <KdTabContent
          value={value}
          index={0}
        >
          Tab content 1
        </KdTabContent>
        <KdTabContent
          value={value}
          index={1}
        >
          Tab content 2
        </KdTabContent>
        <KdTabContent
          value={value}
          index={2}
        >
          Tab content 3
        </KdTabContent>
      </div>
    </>
  );
};

export default GenericComponents;
