import React from 'react';

interface Props {
  disabled?: boolean;
}

const KdBreakpoints: React.FC<Props> = ({ disabled = false }) => (
  <span className="bg-red-400 text-[10px] py-[2px] font-bold absolute top-0 right-0 z-[99999]">
    <span className="mx-1 text-slate-500 xs:text-white">xs</span>
    <span className="mx-1 text-slate-500 sm:text-white">sm</span>
    <span className="mx-1 text-slate-500 md:text-white">md</span>
    <span className="mx-1 text-slate-500 mda:text-white">mda</span>
    <span className="mx-1 text-slate-500 lg:text-white">lg</span>
    <span className="mx-1 text-slate-500 xl:text-white">xl</span>
    <span className="mx-1 text-slate-500 2xl:text-white">2xl</span>
    <span className="mx-1 text-slate-500 tablet:text-white">tablet</span>
    <span className="mx-1 text-slate-500 short:text-white">short</span>
  </span>
);

export default KdBreakpoints;
