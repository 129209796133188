import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';
import FeatureFlagButton from './FeatureFlagButton';

const FeatureFlagsList = () => {
  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );

  const featureNames = Object.keys(featureFlagStore);

  return (
    <div className="flex flex-col">
      {featureNames.map((featureName) => (
        <div
          className="flex flex-col sm:flex-row sm:just"
          key={featureName}
        >
          <div className="w-[400px]">{featureName}</div>
          <div className="flex justify-end gap-3">
            <FeatureFlagButton
              featureName={featureName}
              featureEnabled={featureFlagStore[featureName]}
              buttonStatus="enable"
            />
            <FeatureFlagButton
              featureName={featureName}
              featureEnabled={featureFlagStore[featureName]}
              buttonStatus="disable"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFlagsList;
