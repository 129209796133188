import { useSelector } from 'react-redux';

import KdButton from '../../../shared/KdButton';
import { RootState } from '../../../store';

type Props = {};

const SentryTester = (props: Props) => {
  const mockedError = () => {
    throw new Error('This is a mocked error');
  };
  const featureFlagStore = useSelector(
    (state: RootState) => state.featureFlagStore
  );
  const errorReporting = featureFlagStore['errorReporting'];

  return (
    <div>
      <KdButton
        type="button"
        onClick={mockedError}
      >
        Throw a mock error
      </KdButton>
      <p className="pt-10">
        The `errorReporting` feature flag is{' '}
        {errorReporting
          ? 'enabled'
          : 'disabled. Customer error report dialog is shown only if the feature is enabled.'}
        .
      </p>
    </div>
  );
};

export default SentryTester;
