// corrects the paythru malformed URL when it redirects back to us.
// example:
// paythru: http://localhost:3000/?transactionKey=75145776d41358a49175fc6c4713ff75#/developers/successful-payment
// should be: http://localhost:3000/#/developers/successful-payment?transactionKey=75145776d41358a49175fc6c4713ff75
export const correctPayThruUrl = (url: string) => {
  const urlParts = url.split('?');
  if (urlParts.length < 2) {
    return url;
  }
  const urlSubParts = urlParts[1]?.split('#');
  const newUrl = urlParts[0] + '#' + urlSubParts[1] + '?' + urlSubParts[0];
  return newUrl;
};
